.App {
  text-align: center;
}

.name-plate {
  box-shadow: 0 2px 4px hsla(0,0%,0%,.3);
  background-color: #404040;
  color: #F5F5F5;
}

img.headshot {
  width: 150px;
}

.name {
  font-size: 35px;
}

.title {
  font-weight: 700;
  padding-bottom: 10px;
}

.education-container {
  background-color: #232D4B;
  box-shadow: 0 2px 4px hsla(0,0%,0%,.3);
  width: 90%;
  border-radius: 10px;
  margin: 15px auto;
  color: #f2f2f2;
}

img.edu-logo {
  width: 200px;
  margin-top: 10px;
  margin-bottom: 5px;;
}

.edu-dates {
  margin-bottom: 5px;
}

.edu-description {
  padding-bottom: 5px;
}